import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../fms-web/src/environments/environment';
import { HttpInterceptorConfig } from '../models/http-interceptor-config.model';
import { HttpInterceptorParams } from '../models/http-interceptor-params.model';
import { PaginatedObjectResult } from '../models/paginated-object-result.model';

export abstract class DataService {
  protected url: string;

  constructor(
    public http: HttpClient,
    private urlString: string,
    private baseUrl?: string,
  ) {
    if (!baseUrl) {
      this.url =
        this.urlString === ''
          ? environment.baseApiUrl
          : `${environment.baseApiUrl}/${urlString}`;
    } else {
      this.url =
        this.urlString === '' ? this.baseUrl! : `${this.baseUrl}/${urlString}`;
    }
  }

  private getOne<T>(
    url: string,
    params?: HttpInterceptorConfig,
  ): Observable<T> {
    const options = {
      params: new HttpInterceptorParams(params),
    };

    return this.http.get<T>(url, options);
  }

  public getOneByPath<T>(
    path: string,
    params?: HttpInterceptorConfig,
  ): Observable<T> {
    const url = `${this.url}/${path}`;

    return this.getOne<T>(url, params);
  }

  public post<T>(
    path: string,
    data: any,
    params?: HttpInterceptorConfig,
  ): Observable<T> {
    const body = data ? JSON.stringify(data) : {};
    const url = `${this.url}/${path}`;
    const options = {
      params: new HttpInterceptorParams(params),
    };

    return this.http.post<T>(url, body, options);
  }

  public getPaginatedWithFilter<T, V>(
    pageIndex: number,
    itemsNumber: number,
    sortField: string,
    sortDirection?: string,
    filter?: V,
    params?: HttpInterceptorConfig,
  ): Observable<PaginatedObjectResult<T>> {
    if (!sortField) {
      sortField = '';
    }
    if (!sortDirection) {
      sortDirection = '';
    }
    const path = `paginated?pageIndex=${pageIndex}&itemsNumber=${itemsNumber}&sortField=${sortField}&sortDirection=${sortDirection}`;
    return this.post<PaginatedObjectResult<T>>(path, filter, params);
  }

  public getMany<T>(
    path: string,
    params?: HttpInterceptorConfig,
  ): Observable<T[]> {
    const url = path ? `${this.url}/${path}` : `${this.url}`;
    const options = {
      params: new HttpInterceptorParams(params),
    };

    return this.http.get<T[]>(url, options);
  }

  public getAll<T>(params?: HttpInterceptorConfig): Observable<T[]> {
    return this.getMany<T>('', params);
  }

  public put<T>(
    path: string,
    data: any,
    params?: HttpInterceptorConfig,
  ): Observable<T> {
    const body = data ? JSON.stringify(data) : {};
    const url = `${this.url}/${path}`;
    const options = {
      params: new HttpInterceptorParams({
        showSuccessMessage: true,
        ...params,
      }),
    };

    return this.http.put<T>(url, body, options);
  }
}
